<template>
  <div class="card card-custom card-transparent">
    <div class="card-body p-0">
      <!--begin: Wizard-->
      <div
        class="wizard wizard-4"
        id="kt_wizard_v4"
        data-wizard-state="step-first"
        data-wizard-clickable="true"
      >
        <!--begin: Wizard Nav-->
        <div class="wizard-nav">
          <div class="wizard-steps">
            <div
              class="wizard-step"
              data-wizard-type="step"
              data-wizard-state="current"
            >
              <div class="wizard-wrapper">
                <div class="wizard-number">
                  1
                </div>
                <div class="wizard-label">
                  <div class="wizard-title">
                    Create internetless tracker device
                  </div>
                  <div class="wizard-desc">
                    Create Custom Device
                  </div>
                </div>
              </div>
            </div>
            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-wrapper">
                <div class="wizard-number">
                  2
                </div>
                <div class="wizard-label">
                  <div class="wizard-title">
                    Create internetless sensor device
                  </div>
                  <div class="wizard-desc">
                    Create Custom Device
                  </div>
                </div>
              </div>
            </div>
            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-wrapper">
                <div class="wizard-number">
                  3
                </div>
                <div class="wizard-label">
                  <div class="wizard-title">
                    Make Payment
                  </div>
                  <div class="wizard-desc">
                    Add Payment Options
                  </div>
                </div>
              </div>
            </div>
            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-wrapper">
                <div class="wizard-number">
                  4
                </div>
                <div class="wizard-label">
                  <div class="wizard-title">
                    Completed
                  </div>
                  <div class="wizard-desc">
                    Review and Submit
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end: Wizard Nav -->

        <!--begin: Wizard Body-->
        <div class="card card-custom card-shadowless rounded-top-0">
          <div class="card-body p-0">
            <div class="row justify-content-center py-8 px-8 py-lg-15 px-lg-10">
              <div class="col-xl-12 col-xxl-7">
                <!--begin: Wizard Form-->
                <form class="form mt-0 mt-lg-10" id="kt_form">
                  <!--begin: Wizard Step 1-->
                  <div
                    class="pb-5"
                    data-wizard-type="step-content"
                    data-wizard-state="current">
                    <div class="mb-10 font-weight-bold text-dark">
                      Enter your Account Details
                    </div>
                    <div class="form-group">
                      <label>Device name</label>
                      <input
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        name="fname"
                        v-model="device_name"
                        placeholder="Name of the device"
                        value="Tracker"
                      />
                      <span class="form-text text-muted"
                        >Enter the name of the device to create</span
                      >
                    </div>
                    <div class="form-group">
                      <label>Location</label>
                      <input
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        name="lname"
                        v-model="device_location"
                        placeholder="Location"
                        value="Delivery car"
                      />
                      <span class="form-text text-muted"
                        >Please enter the location of the device.</span
                      >
                    </div>

                    <div class="row">
                      <div class="col-xl-6">
                        <div class="form-group">
                          <label>Token</label>
                          <input
                            type="text"
                            class="form-control form-control-solid form-control-lg"
                            name="token"
                            v-model="device_token"
                            placeholder="Token"
                            value=""
                          />
                          <span class="form-text text-muted"
                            >Random token created.</span
                          >
                        </div>
                      </div>
                      <div class="col-xl-6">
                        <div class="form-group">
                          <label>Secret token</label>
                          <input
                            type="text"
                            class="form-control form-control-solid form-control-lg"
                            name="secret_token"
                            placeholder="Secret token"
                            v-model="device_secret_token"
                            value=""
                          />
                          <span class="form-text text-muted"
                            >Random secret token created.</span
                          >
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <label>BT ID key</label>
                      <input
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        name="lname"
                        placeholder="BT Hashed adv key"
                        value=""
                        v-model="device_bt_key"
                      />
                      <span class="form-text text-muted"
                        >Place the BT id key in the form of a
                        base64 (key id from openhaystack)</span
                      >
                    </div>
                    <div class="form-group">
                      <label>BT Hashed adv key</label>
                      <input
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        name="lname"
                        placeholder="BT id key"
                        value=""
                        v-model="device_bt_private_key"
                      />
                      <span class="form-text text-muted"
                        >Place the BT adv key in the form of a
                        base64 (private key openhaystack).</span
                      >
                    </div>
                    <div class="form-group">
                      <label>User id</label>
                      <input
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        name="lname"
                        placeholder="User id"
                        value=""
                        v-model="user_id"
                      />
                      <span class="form-text text-muted"
                        >Add a user id to assign this device.</span
                      >
                    </div>

                    <div class="form-group">
                      <label>Device type</label>
                      <select
                        name="devicetype"
                        v-model="device_type"
                        class="form-control form-control-solid form-control-lg"
                      >
                        <option value="">Select</option>
                        <!-- <option value="suitchv3">Bulb</option>
                        <option value="thermostatv1">Thermostat</option>
                        <option value="camv1">Video</option> -->
                        <option value="trackerv1">Tracker</option>
                      </select>
                    </div>
                    <b-button
                      @click="createBLEDevice"
                      variant="danger"
                      :ref="'action-button-internetless'"
                    >
                      <b-spinner small v-show="shouldBeSpinning()"></b-spinner>
                      Create</b-button
                    >
                  </div>
                  <!--end: Wizard Step 1-->

                  <!--begin: Wizard Step 2-->
                  <div
                    class="pb-5"
                    data-wizard-type="step-content"
                    data-wizard-state="current">
                    <div class="mb-10 font-weight-bold text-dark">
                      Enter the device details
                    </div>
                    <div class="form-group">
                      <label>Device name</label>
                      <input
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        name="fname"
                        v-model="sensor_device_name"
                        placeholder="Name of the device"
                        value="Soil sensor"
                      />
                      <span class="form-text text-muted"
                        >Enter the name of the device to create</span
                      >
                    </div>
                    <div class="form-group">
                      <label>Location</label>
                      <input
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        name="lname"
                        v-model="sensor_device_location"
                        placeholder="Location"
                        value="Garden"
                      />
                      <span class="form-text text-muted"
                        >Please enter the location of the device.</span
                      >
                    </div>

                    <div class="row">
                      <div class="col-xl-6">
                        <div class="form-group">
                          <label>Token</label>
                          <input
                            type="text"
                            class="form-control form-control-solid form-control-lg"
                            name="token"
                            v-model="device_token"
                            placeholder="Token"
                            value=""
                          />
                          <span class="form-text text-muted"
                            >Random token created.</span
                          >
                        </div>
                      </div>
                      <div class="col-xl-6">
                        <div class="form-group">
                          <label>Secret token</label>
                          <input
                            type="text"
                            class="form-control form-control-solid form-control-lg"
                            name="secret_token"
                            placeholder="Secret token"
                            v-model="device_secret_token"
                            value=""
                          />
                          <span class="form-text text-muted"
                            >Random secret token created.</span
                          >
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <label>JSON keys and values</label>
                    <b-form-textarea
                      id="textarea"
                      v-model="sensor_device_description"
                      placeholder="Enter JSON data"
                      rows="3"
                      max-rows="6"
                    ></b-form-textarea>
                  </div>
                    <div class="form-group">
                      <label>User id</label>
                      <input
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        name="lname"
                        placeholder="User id"
                        value=""
                        v-model="user_id"
                      />
                      <span class="form-text text-muted"
                        >Add a user id to assign this device.</span
                      >
                    </div>

                    <div class="form-group">
                      <label>Device type</label>
                      <select
                        name="devicetype"
                        v-model="sensor_device_type"
                        class="form-control form-control-solid form-control-lg"
                      >
                        <option value="">Select</option>
                        <!-- <option value="suitchv3">Bulb</option>
                        <option value="thermostatv1">Thermostat</option>
                        <option value="camv1">Video</option> -->
                        <option value="soilblessv1">Soil sensor</option>
                        <option value="templessv1">Temperature sensor</option>
                      </select>
                    </div>
                    <b-button
                      @click="createBLESensor"
                      variant="danger"
                      :ref="'action-button-internetless'"
                    >
                      <b-spinner small v-show="shouldBeSpinning()"></b-spinner>
                      Create</b-button
                    >
                  </div>
                  <!--end: Wizard Step 2-->

                  <!--begin: Wizard Step 3-->
                  <div class="pb-5" data-wizard-type="step-content">
                    <div class="mb-10 font-weight-bold text-dark">
                      Enter your Payment Details
                    </div>
                    <div class="row">
                      <div class="col-xl-6">
                        <div class="form-group">
                          <label>Name on Card</label>
                          <input
                            type="text"
                            class="form-control form-control-solid form-control-lg"
                            name="ccname"
                            placeholder="Card Name"
                            value="John Wick"
                          />
                          <span class="form-text text-muted"
                            >Please enter your Card Name.</span
                          >
                        </div>
                      </div>
                      <div class="col-xl-6">
                        <div class="form-group">
                          <label>Card Number</label>
                          <input
                            type="text"
                            class="form-control form-control-solid form-control-lg"
                            name="ccnumber"
                            placeholder="Card Number"
                            value="4444 3333 2222 1111"
                          />
                          <span class="form-text text-muted"
                            >Please enter your Address.</span
                          >
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-xl-4">
                        <div class="form-group">
                          <label>Card Expiry Month</label>
                          <input
                            type="number"
                            class="form-control form-control-solid form-control-lg"
                            name="ccmonth"
                            placeholder="Card Expiry Month"
                            value="01"
                          />
                          <span class="form-text text-muted"
                            >Please enter your Card Expiry Month.</span
                          >
                        </div>
                      </div>
                      <div class="col-xl-4">
                        <div class="form-group">
                          <label>Card Expiry Year</label>
                          <input
                            type="number"
                            class="form-control form-control-solid form-control-lg"
                            name="ccyear"
                            placeholder="Card Expire Year"
                            value="21"
                          />
                          <span class="form-text text-muted"
                            >Please enter your Card Expiry Year.</span
                          >
                        </div>
                      </div>
                      <div class="col-xl-4">
                        <div class="form-group">
                          <label>Card CVV Number</label>
                          <input
                            type="password"
                            class="form-control form-control-solid form-control-lg"
                            name="cccvv"
                            placeholder="Card CVV Number"
                            value="123"
                          />
                          <span class="form-text text-muted"
                            >Please enter your Card CVV Number.</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--end: Wizard Step 3-->

                  <!--begin: Wizard Step 4-->
                  <div class="pb-5" data-wizard-type="step-content">
                    <div class="mb-10 font-weight-bold text-dark">
                      Review your Details and Submit
                    </div>
                    <div class="border-bottom mb-5 pb-5">
                      <div class="font-weight-bold mb-3">
                        Your Account Details:
                      </div>
                      <div class="line-height-md">
                        John Wick
                        <br />
                        Phone: +61412345678 <br />
                        Email: johnwick@reeves.com
                      </div>
                    </div>
                    <div class="border-bottom mb-5 pb-5">
                      <div class="font-weight-bold mb-3">
                        Your Address Details:
                      </div>
                      <div class="line-height-md">
                        Address Line 1
                        <br />
                        Address Line 2 <br />
                        Melbourne 3000, VIC, Australia
                      </div>
                    </div>
                    <div class="mb-5">
                      <div class="font-weight-bold mb-3">
                        Payment Details:
                      </div>
                      <div class="line-height-md">
                        Card Number: xxxx xxxx xxxx 1111
                        <br />
                        Card Name: John Wick <br />
                        Card Expiry: 01/21
                      </div>
                    </div>
                  </div>
                  <!--end: Wizard Step 4-->

                  <!--begin: Wizard Actions -->
                  <!-- <div class="d-flex justify-content-between border-top pt-10">
                    <div class="mr-2">
                      <button
                        class="btn btn-light-primary font-weight-bold text-uppercase px-9 py-4"
                        data-wizard-type="action-prev"
                      >
                        Previous
                      </button>
                    </div>
                    <div>
                      <button
                        v-on:click="submit"
                        class="btn btn-success font-weight-bold text-uppercase px-9 py-4"
                        data-wizard-type="action-submit"
                      >
                        Submit
                      </button>
                      <button
                        class="btn btn-primary font-weight-bold text-uppercase px-9 py-4"
                        data-wizard-type="action-next"
                      >
                        Next Step
                      </button>
                    </div>
                  </div> -->
                  <!--end: Wizard Actions -->
                </form>
                <!--end: Wizard Form-->
              </div>
            </div>
          </div>
        </div>
        <!--end: Wizard Bpdy-->
      </div>
      <!--end: Wizard-->
    </div>
  </div>
</template>

<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-4.scss";
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { POST_FINDMY_CREATE_SENSOR, POST_FINDMY_CREATE, SET_ERROR } from "@/core/services/store/devices.module"
// import { DEVICES_TECHNICAL_NAMES } from "@/core/enums/devices-technical-names.enum"
import { SOURCES } from "@/core/enums/sources.enum"
import KTUtil from "@/assets/js/components/util";
import KTWizard from "@/assets/js/components/wizard";
import Swal from "sweetalert2";

export default {
  name: "Wizard-4",
  beforeDestroy() {
    // Unsubscribe from the store when the component is destroyed
    if (this.unsubscribe) {
      this.unsubscribe();console.log("kill_subscribe");
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Wizard", route: "wizard-1" },
      { title: "Wizard-4" }
    ]);

    // Initialize form wizard
    const wizard = new KTWizard("kt_wizard_v4", {
      startStep: 1, // initial active step number
      clickableSteps: true // allow step clicking
    });

    // Validation before going to next page
    wizard.on("beforeNext", function(/*wizardObj*/) {
      // validate the form and use below function to stop the wizard's step
      // wizardObj.stop();
    });

    // Change event
    wizard.on("change", function(/*wizardObj*/) {
      setTimeout(() => {
        KTUtil.scrollTop();
      }, 500);
    });
    this.device_token = this.generateRandomToken();
    this.device_secret_token = this.generateRandomToken();
    this.device_name = "Tracker";
    this.sensor_device_name = "Soil sensor";
    this.device_location = "Custom moving object";
    this.sensor_device_location = "Garden";

    this.unsubscribe=this.$store.subscribe((mutation,state)=>{
      // console.log("mutation-->",mutation.type, state)
      if(mutation.type === SET_ERROR){
        state;
        Swal.fire({
        title: "",
        text: "The application found an issue on the last operation",
        icon: "warning",
        confirmButtonClass: "btn btn-secondary",
      });
      }
    });
  },
  data() {
    return {
      unsubscribe: null,
      global_wizard: {},
      notifications_selected: [],
      device_description: "Faketag device",
      device_name: "",
      device_location: "",
      device_type: "",
      device_public: "no",
      device_bt_key: "",
      user_id: "",
      device_token: "",
      device_secret_token: "",
      device_bt_private_key: "",

      sensor_device_description: "",
      sensor_device_name: "",
      sensor_device_location: "",
      sensor_device_type: "",
      sensor_device_public: "no",
      sensor_device_bt_key: "",
      sensor_device_token: "",
      sensor_secret_token: "",
      sensor_device_bt_private_key: "",
    }
  },
  methods: {
    generateRandomToken(){
      return (Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)).substring(0,8);
    },
    createBLEDevice(){
      this.$store.dispatch(POST_FINDMY_CREATE, {
        source: SOURCES.WEB,
        anet_device:{object:this.device_name,
        location: this.device_location,
        description: this.device_description,
        token: this.device_token,
        secret_token: this.device_secret_token,
        is_public: "0",
        notify_email: "0",
        notify_twitter: "0",
        notify_telegram: "0",
        version: this.device_type,
        notify_fbinbox: '0',
        user_id:null,
        device_bt_key: this.device_bt_key,
        device_bt_privatekey: this.device_bt_private_key}
      });
      // use a console log to display all values from the data section
      console.log(this.$data);
    },
    createBLESensor(){
      this.$store.dispatch(POST_FINDMY_CREATE_SENSOR, {
        source: SOURCES.WEB,
        anet_device:{object:this.sensor_device_name,
        location: this.sensor_device_location,
        description: JSON.stringify(this.sensor_device_description),
        token: this.device_token,
        secret_token: this.device_secret_token,
        is_public: "0",
        notify_email: "0",
        notify_twitter: "0",
        notify_telegram: "0",
        version: this.sensor_device_type,
        notify_fbinbox: '0',
        user_id:null}
      });
      // use a console log to display all values from the data section
      console.log(this.$data);
    },
    shouldBeSpinning(){
      return false;
    },
    submit: function(e) {
      e.preventDefault();
      Swal.fire({
        title: "",
        text: "The application has been successfully submitted!",
        icon: "success",
        confirmButtonClass: "btn btn-secondary"
      });
    }
  }
};
</script>
